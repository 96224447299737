//import { createRouter } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue'; 
import PrivacyPage from './pages/PrivacyPage.vue'; 
import TermsPage from './pages/TermsPage.vue'; 
import DisclaimerPage from './pages/DisclaimerPage.vue'; 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPage,
  },
  {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    component: TermsPage,
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: DisclaimerPage,
  },
  
];

//const router = createRouter({
//  routes,
//});

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;